<template>
  <div class="container-fluid">
    <div class="row mb-5">
      <div class="col-8">
        <span class="display-4 col-12">
          {{ $t("PURCHASES_ORDERS.DELIVERIES_STATE") }}
        </span>
      </div>
    </div>

    <div class="row mb-5">
      <div class="col-12">
        <el-table
          class="table-responsive align-items-center table-flush"
          header-row-class-name="thead-light"
          :data="Object.values(purchasesOrder.deliveryItemsState.orderedItems)"
        >
          <el-table-column
            :label="$t('PURCHASES_ORDERS.DELIVERIES_STATE_ITEM')"
            prop="status"
          >
            <template slot-scope="{ row }">
              <div class="delivery-item-name">
                <span class="code text-bold h3">{{ row.code }}</span>
                <span class="name">{{ row.name }}</span>
                <span class="except text-muted">{{ row.excerpt }}</span>
              </div>
            </template>
          </el-table-column>
          <el-table-column
            :label="$t('PURCHASES_ORDERS.DELIVERIES_STATE_ORDERED_QUANTITY')"
            prop="status"
          >
            <template slot-scope="{ row }">
              <div class="delivery-item-name">
                <span class="code text-bold h3">{{ row.quantity }}</span>
              </div>
            </template>
          </el-table-column>
          <el-table-column
            :label="$t('PURCHASES_ORDERS.DELIVERIES_STATE_DELIVERED_QUANTITY')"
            prop="status"
          >
            <template slot-scope="{ row }">
              <div class="delivery-item-name">
                <span class="code text-bold h3">
                  {{
                    purchasesOrder.deliveryItemsState.deliveredItems[row.id]
                      .quantity
                  }}
                </span>
              </div>
            </template>
          </el-table-column>
          <el-table-column
            :label="$t('PURCHASES_ORDERS.DELIVERIES_STATE_REMAINING_QUANTITY')"
            prop="status"
          >
            <template slot-scope="{ row }">
              <div class="delivery-item-name">
                <span class="code text-bold h3">
                  {{
                    purchasesOrder.deliveryItemsState.remainingItems[row.id]
                      .quantity
                  }}
                </span>
              </div>
            </template>
          </el-table-column>
        </el-table>
      </div>
    </div>
  </div>
</template>

<script>
import { Table, TableColumn } from "element-ui";
import formMixin from "@/mixins/form-mixin";

export default {
  name: "purchases-order-view-deliveries",

  components: {
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
  },

  mixins: [formMixin],

  props: ["purchasesOrder"],

  data() {
    return {};
  },

  computed: {},

  methods: {},

  mounted() {},

  watch: {},
};
</script>
<style scoped>
.delivery-item-name {
  display: flex;
  flex-direction: column;
  justify-content: center;
}
</style>
