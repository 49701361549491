import moment from "moment";

export default {
  type: "purchases-orders",
  expiration_time: moment().add(30, "days").format("YYYY-MM-DD"),
  code: null,
  excerpt: "",
  discounts: [],
  deliveryItemsState: {
    orderedItems: {},
    deliveredItems: {},
    remainingItems: {},
    orderDelivered: false,
  },
  relationshipNames: ["issuer", "organization", "allowedLocations", "purchasesInvoice"],
  issuer: {
    type: 'suppliers',
    id: null,
  },
  organization: {
    type: "organizations",
    id: null,
  },
  purchasesInvoice: {
    type: "purchases-invoices",
    id: null,
  },
  items: [],
  allowedLocations: [],
};
